<template>
    <div>
        <div id="myNonChart" style="width: 500px;height: 400px;"></div>
    </div>
</template>
<script>
import * as mecharts from 'echarts'
import { monitormanagerAPI } from 'liankong-ui-api'
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            initNonCount: '0MB',
            alreadyNonCount: '0MB',
            nowNonCount: '0MB',
            maxNonCount: 0,
            myChart: '',
            initArr: [], // 初始空间数据
            nonAlreadyArr: [], // 已用数据存储
            nonuseArr: [], // 可使用数据存储
            nonTimer: null,
            nonHeapTimers: null,
            option: {},
            keepDataDown:[],
            keepDataUp:[],
            keepDataMidd: []
        }
    },
    created(){
        this.getMaxNonLists();
        this.getInitLists();
        this.getAlreadyUsedNonLists();
        this.getNowUsedNonList();
       

        this.nonTimer = setInterval(()=>{
            this.getInitLists();
            this.getAlreadyUsedNonLists();
            this.getNowUsedNonList();
        }, 1000);
    },
    mounted(){
        this.nonHeapTimers = setInterval(()=>{
            let base = new Date().getTime(); // 当前时间戳
            // 当前时间的前 半 个小时
            let frontOneHour = new Date(new Date().getTime() - 1 * 60 * 30 * 1000);
            // 当前时间的前 半 个小时 ==> 时间戳
            let frontTimes = Date.parse(frontOneHour);
            // 前 半 个小时
            let prevHour = 1 * 60 * 30 * 1000
            // 初始空间
            let firstIndex = this.initNonCount.indexOf('MB');
            let newInitNonCount = this.initNonCount.substring(0, firstIndex)
            let initNonCounts = newInitNonCount - 2
            let arrCount = 0
            // 已用
            let towIndex = this.alreadyNonCount.indexOf('MB');
            let towNewIndex = Number(this.alreadyNonCount.substring(0, towIndex));
            // 可用
            let treeIndex = this.nowNonCount.indexOf('MB');
            let treeNewIndex = Number(this.nowNonCount.substring(0, treeIndex));
            
            let data = [];
            let data2 = [];
            let data3 = [];
            for (var i = 1; i < 10; i++) {
                var now = new Date((base -= prevHour));
               
                var dayStr = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' +  now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes(); // 年月日
                if(i % 2 == 0){
                    arrCount = initNonCounts
                } else {
                    arrCount = Number(newInitNonCount)
                }
                data.push([dayStr,arrCount]);
                data2.push([dayStr, towNewIndex]);
                data3.push([dayStr, treeNewIndex]);
                
                this.keepDataDown = data
                this.keepDataMidd = data2
                this.keepDataUp = data3
            }
            this.option = {
            legend: {
                top: 30,
                left: 'center',
                textStyle: {
                    fontSize: 14
                },
                data: [{
                    'name': '初始空间',
                    'icon': 'rect'
                },{
                    'name': '已用',
                    'icon': 'rect'
                },{
                    'name': '当前可用',
                    'icon': 'rect'
                },{
                    'name': '最大',
                    'icon': 'none'
                }],
                formatter: (name)=>{
                    if(name === '初始空间') {
                        return name + "：" + this.initNonCount;
                    } else if(name === '已用') {
                        return name + "：" + this.alreadyNonCount;
                    } else if(name === '当前可用') {
                        return name + "：" + this.nowNonCount;
                    } else if(name === '最大') {
                        return name + "：" + this.maxNonCount;
                    }
                }
            },
            tooltip: {
                triggerOn: 'none',
                position: function (pt) {
                    return [pt[0], 130];
                }
            },
            xAxis: {
                type: 'time',
                axisPointer: {
                    // value: '2016-10-7',
                    snap: true,
                    lineStyle: {
                        color: '#7581BD',
                        width: 2
                    },
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    showMaxLabel: true
                }
            },
            yAxis: {
                name: 'MB',
                type: 'value',
                axisTick: { // 刻度
                    show: true,
                    inside: false,
                    boundaryGap: true,
                    alignWithLabel: true,
                },
                splitLine: {
                 show: false
                },
                axisLabel: { // 刻度标签
                    inside: true,
                    formatter: [0,50,100,150,200]
                },
                axisLine: {
                    show: true,
                },
                z: 10
            },
            grid: {
                top: 110,
                left: 15,
                right: 15,
                height: 160
            },
            series: [{
                        name: '初始空间',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#68DCB7'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#68DCB7'
                            },
                            {
                                offset: 1,
                                color: '#68DCB7'
                            }
                            ])
                        },
                        data: data
                    },
                    {
                        name: '已用',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#0770FF'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                        data: data2
                    },
                    {
                    name: '当前可用',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#F2597F'
                    },
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(213,72,120,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(213,72,120,0.3)'
                        }
                        ])
                    },
                        data: data3
                    },
                    {
                        name: '最大',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
            };

            this.option && this.myChart.setOption(this.option);
        }, 1800000)
    },
    methods: {
        // 初始空间
        getInitLists(){
            monitormanagerAPI.getNonInitList(this.id).then(res=>{
                this.initNonCount = this.formatSizeUnits(res.measurements[0].value);

                let that = this
                that.option.legend = {
                    top: 30,
                    left: 'center',
                    textStyle: {
                        fontSize: 14
                    },
                    data: [{
                        'name': '初始空间',
                        'icon': 'rect'
                    },{
                        'name': '已用',
                        'icon': 'rect'
                    },{
                        'name': '当前可用',
                        'icon': 'rect'
                    },{
                        'name': '最大',
                        'icon': 'none'
                    }],
                    formatter: (name)=>{
                        if(name === '初始空间') {
                            return name + "：" + this.initNonCount;
                        } else if(name === '已用') {
                            return name + "：" + this.alreadyNonCount;
                        } else if(name === '当前可用') {
                            return name + "：" + this.nowNonCount;
                        } else if(name === '最大') {
                            return name + "：" + this.maxNonCount;
                        }
                    }
                }
                let firstIndex = this.initNonCount.indexOf('MB');
                let newInitNonCount = this.initNonCount.substring(0, firstIndex)
                let initNonCounts = newInitNonCount - 2
                let arrCount = 0
                this.keepDataDown.forEach((item,i)=>{
                    if(i % 2 == 0){
                        item[1] = initNonCounts
                    } else {
                        item[1] = Number(newInitNonCount)
                    }
                })
                that.option.series = [{
                        name: '初始空间',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#68DCB7'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#68DCB7'
                            },
                            {
                                offset: 1,
                                color: '#68DCB7'
                            }
                            ])
                        },
                        data: this.keepDataDown
                    },
                    {
                        name: '已用',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#0770FF'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                        data: this.keepDataMidd
                    },
                    {
                    name: '当前可用',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#F2597F'
                    },
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(213,72,120,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(213,72,120,0.3)'
                        }
                        ])
                    },
                        data: this.keepDataUp
                    },
                    {
                        name: '最大',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
                that.myChart.setOption(that.option);
            })
        },
        // 已用
        getAlreadyUsedNonLists(){
            monitormanagerAPI.getAlreadyNonLists(this.id).then(res=>{
                this.alreadyNonCount = this.formatSizeUnits(res.measurements[0].value);

                let that = this
                that.option.legend = {
                    top: 30,
                    left: 'center',
                    textStyle: {
                        fontSize: 14
                    },
                    data: [{
                        'name': '初始空间',
                        'icon': 'rect'
                    },{
                        'name': '已用',
                        'icon': 'rect'
                    },{
                        'name': '当前可用',
                        'icon': 'rect'
                    },{
                        'name': '最大',
                        'icon': 'none'
                    }],
                    formatter: (name)=>{
                        if(name === '初始空间') {
                            return name + "：" + this.initNonCount;
                        } else if(name === '已用') {
                            return name + "：" + this.alreadyNonCount;
                        } else if(name === '当前可用') {
                            return name + "：" + this.nowNonCount;
                        } else if(name === '最大') {
                            return name + "：" + this.maxNonCount;
                        }
                    }
                }
                let towIndex = this.alreadyNonCount.indexOf('MB');
                let towNewIndex = Number(this.alreadyNonCount.substring(0, towIndex));
                this.keepDataMidd.forEach((item,i)=>{
                    item[1] = Number(towNewIndex)
                })
                that.option.series = [{
                        name: '初始空间',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#68DCB7'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#68DCB7'
                            },
                            {
                                offset: 1,
                                color: '#68DCB7'
                            }
                            ])
                        },
                        data: this.keepDataDown
                    },
                    {
                        name: '已用',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#0770FF'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                        data: this.keepDataMidd
                    },
                    {
                    name: '当前可用',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#F2597F'
                    },
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(213,72,120,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(213,72,120,0.3)'
                        }
                        ])
                    },
                        data: this.keepDataUp
                    },
                    {
                        name: '最大',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
                that.myChart.setOption(that.option);
            })
        },
        // 当前可用
        getNowUsedNonList(){
            monitormanagerAPI.getNowUsedNonLists(this.id).then(res=>{
                this.nowNonCount = this.formatSizeUnits(res.measurements[0].value);

                let that = this
                that.option.legend = {
                    top: 30,
                    left: 'center',
                    textStyle: {
                        fontSize: 14
                    },
                    data: [{
                        'name': '初始空间',
                        'icon': 'rect'
                    },{
                        'name': '已用',
                        'icon': 'rect'
                    },{
                        'name': '当前可用',
                        'icon': 'rect'
                    },{
                        'name': '最大',
                        'icon': 'none'
                    }],
                    formatter: (name)=>{
                        if(name === '初始空间') {
                            return name + "：" + this.initNonCount;
                        } else if(name === '已用') {
                            return name + "：" + this.alreadyNonCount;
                        } else if(name === '当前可用') {
                            return name + "：" + this.nowNonCount;
                        } else if(name === '最大') {
                            return name + "：" + this.maxNonCount;
                        }
                    }
                }
                let treeIndex = this.nowNonCount.indexOf('MB');
                let treeNewIndex = Number(this.nowNonCount.substring(0, treeIndex));
                this.keepDataUp.forEach((item,i)=>{
                    item[1] = Number(treeNewIndex)
                })
                that.option.series = [{
                        name: '初始空间',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#68DCB7'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#68DCB7'
                            },
                            {
                                offset: 1,
                                color: '#68DCB7'
                            }
                            ])
                        },
                        data: this.keepDataDown
                    },
                    {
                        name: '已用',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#0770FF'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                        data: this.keepDataMidd
                    },
                    {
                    name: '当前可用',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#F2597F'
                    },
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(213,72,120,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(213,72,120,0.3)'
                        }
                        ])
                    },
                        data: this.keepDataUp
                    },
                    {
                        name: '最大',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
                that.myChart.setOption(that.option);
            })
        },
        // 最大
        getMaxNonLists(){
            monitormanagerAPI.getMaxUseNonLists(this.id).then(res=>{
                this.maxNonCount = this.formatSizeUnits(res.measurements[0].value);

                this.onloads();
            })
        },
        // 转换字节
        formatSizeUnits(bytes){
            if (bytes >= 1073741824) {
                bytes = (bytes / 1073741824).toFixed(2) + " GB"; 
            } else if (bytes >= 1048576) { 
                bytes = (bytes / 1048576).toFixed(2) + " MB"; 
            } else if (bytes >= 1024) { 
                bytes = (bytes / 1024).toFixed(2) + " KB";
            } else if (bytes > 1) {
                bytes = bytes + " bytes";
            } else if (bytes == 1) {
                bytes = bytes + " byte";
            } else { 
                bytes = "0 bytes";
            }
            return bytes;
        },
        onloads(){
            if(this.myChart!='' && this.myChart!= null){
                this.myChart.dispose();
            }
            this.myChart = mecharts.init(document.getElementById('myNonChart'));
            // let options

            let base = new Date().getTime(); // 当前时间戳
            // 当前时间的前 半 个小时
            let frontOneHour = new Date(new Date().getTime() - 1 * 60 * 30 * 1000);
            // 当前时间的前 半 个小时 ==> 时间戳
            let frontTimes = Date.parse(frontOneHour);
            // 前 半 个小时
            let prevHour = 1 * 60 * 30 * 1000
            // 初始空间
            let firstIndex = this.initNonCount.indexOf('MB');
            let newInitNonCount = this.initNonCount.substring(0, firstIndex)
            let initNonCounts = newInitNonCount - 2
            let arrCount = 0
            // 已用
            let towIndex = this.alreadyNonCount.indexOf('MB');
            let towNewIndex = Number(this.alreadyNonCount.substring(0, towIndex));
            // 可用
            let treeIndex = this.nowNonCount.indexOf('MB');
            let treeNewIndex = Number(this.nowNonCount.substring(0, treeIndex));
            
            let data = [];
            let data2 = [];
            let data3 = [];
            for (var i = 1; i < 10; i++) {
                var now = new Date((base -= prevHour));
               
                var dayStr = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' +  now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes(); // 年月日
                if(i % 2 == 0){
                    arrCount = initNonCounts
                } else {
                    arrCount = Number(newInitNonCount)
                }
                data.push([dayStr,arrCount]);
                data2.push([dayStr, towNewIndex]);
                data3.push([dayStr, treeNewIndex]);
                
                this.keepDataDown = data
                this.keepDataMidd = data2
                this.keepDataUp = data3
            }
            this.option = {
            legend: {
                top: 30,
                left: 'center',
                textStyle: {
                    fontSize: 14
                },
                data: [{
                    'name': '初始空间',
                    'icon': 'rect'
                },{
                    'name': '已用',
                    'icon': 'rect'
                },{
                    'name': '当前可用',
                    'icon': 'rect'
                },{
                    'name': '最大',
                    'icon': 'none'
                }],
                formatter: (name)=>{
                    if(name === '初始空间') {
                        return name + "：" + this.initNonCount;
                    } else if(name === '已用') {
                        return name + "：" + this.alreadyNonCount;
                    } else if(name === '当前可用') {
                        return name + "：" + this.nowNonCount;
                    } else if(name === '最大') {
                        return name + "：" + this.maxNonCount;
                    }
                }
            },
            tooltip: {
                triggerOn: 'none',
                position: function (pt) {
                    return [pt[0], 130];
                }
            },
            xAxis: {
                type: 'time',
                axisPointer: {
                    // value: '2016-10-7',
                    snap: true,
                    lineStyle: {
                        color: '#7581BD',
                        width: 2
                    },
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    showMaxLabel: true
                }
            },
            yAxis: {
                name: 'MB',
                type: 'value',
                axisTick: { // 刻度
                    show: true,
                    inside: false,
                    boundaryGap: true,
                    alignWithLabel: true,
                },
                splitLine: {
                 show: false
                },
                axisLabel: { // 刻度标签
                    inside: true,
                    formatter: [0,50,100,150,200]
                },
                axisLine: {
                    show: true,
                },
                z: 10
            },
            grid: {
                top: 110,
                left: 15,
                right: 15,
                height: 160
            },
            series: [{
                        name: '初始空间',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#68DCB7'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#68DCB7'
                            },
                            {
                                offset: 1,
                                color: '#68DCB7'
                            }
                            ])
                        },
                        data: data
                    },
                    {
                        name: '已用',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#0770FF'
                        },
                        stack: 'a',
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(58,77,233,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(58,77,233,0.3)'
                            }
                            ])
                        },
                        data: data2
                    },
                    {
                    name: '当前可用',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#F2597F'
                    },
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(213,72,120,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(213,72,120,0.3)'
                        }
                        ])
                    },
                        data: data3
                    },
                    {
                        name: '最大',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
            };

            this.option && this.myChart.setOption(this.option);
        }
    },
    destroyed(){
        clearInterval(this.nonTimer);
        clearInterval(this.nonHeapTimers);
    }
}
</script>
<style lang="less" scoped>

</style>