<template> 
    <div class="server-wraps">
        <div class="boxs">
            <el-button class="up-downbtn">{{monitorNames.status == 'UP' ? '暂停服务' : '启动服务'}}</el-button>
        </div>
       <div style="margin-bottom: 20px;">
          <el-row :gutter="24">
              <el-col :span="24">
                  <el-card>
                    <div slot="header">
                      <span>应用实例信息</span>
                    </div>
                    <div class="el-table el-table--enable-row-hover el-table--medium">
                      <table cellspacing="0" style="width: 100%">
                        <tbody>
                          <tr>
                            <td><div class="cell">应用实例名称：</div></td>
                            <td>
                              <div class="cell" v-if="monitorNames">
                                {{ monitorNames.name }}
                              </div>
                            </td>
                            <td><div class="cell"></div></td>
                            <td>
                              <div class="cell">
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><div class="cell">应用实例Id：</div></td>
                            <td>
                              <div class="cell" v-if="id">
                                {{ id }}
                              </div>
                            </td>
                            <td><div class="cell"></div></td>
                            <td>
                              <div class="cell">
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-card>
              </el-col>
          </el-row>
        </div>
        <el-row :gutter="24" style="margin-bottom: 20px;" v-if="isHealthFlag">
          <el-col :span="24" class="card-box top">
              <el-card>
                <div slot="header"><span>健康 --- elasticsearch</span></div>
                <HealthItem :healthyDetailInfos="healthyDetailInfos"></HealthItem>
              </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12" class="card-box top">
              <el-card>
                <div slot="header"><span>进程</span></div>
                <div class="level">
                    <div>
                      <span>进程ID</span>
                      <span>{{processId}}</span>
                    </div>
                    <div>
                      <span>运行时间</span>
                      <span>{{processRunTime}}</span>
                    </div>
                    <div>
                      <span>进程CPU使用率</span>
                      <span>{{processCpuUserd}}</span>
                    </div>
                    <div>
                      <span>系统CPU使用率</span>
                      <span>{{processSysCpuCount}}</span>
                    </div>
                    <div>
                      <span>CPU核心数</span>
                      <span>{{cpuCount}}</span>
                    </div>
                </div>
              </el-card>

              <el-card style="margin-top: 20px;">
                  <div slot="header">
                    <span>垃圾回收</span>
                  </div>
                  <div class="level">
                     <div>
                      <span>总计</span>
                      <span>{{gabrageList.totals}}</span>
                    </div>
                    <div>
                      <span>总耗时</span>
                      <span>{{gabrageList.totalTimes + 's'}}</span>
                    </div>
                    <div>
                      <span>最大耗时</span>
                      <span>{{gabrageList.maxTimes + 's'}}</span>
                    </div>
                  </div>
              </el-card>
          </el-col>
          <el-col :span="12" class="card-box top">
            <el-card>
              <div slot="header"><span>线程</span></div>
              <LineEcharts :id="id"></LineEcharts>
            </el-card>
          </el-col>
        </el-row>
        <div style="margin-top: 20px;">
            <el-row :gutter="24">
               <el-col :span="12">
                  <el-card>
                    <div slot="header"><span>内存: Heap</span></div>
                    <MemoryEharts :id="id"></MemoryEharts>
                   </el-card>
                </el-col>
                 <el-col :span="12">
                  <el-card>
                    <div slot="header"><span>内存: Non heap</span></div>
                    <CpuEharts :id="id"></CpuEharts>
                   </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import MemoryEharts from './MemoryEharts.vue'
import CpuEharts from './CpuEcharts.vue'
import LineEcharts from './LineEcharts.vue'
import HealthItem from './HealthItem.vue'
import { monitormanagerAPI } from 'liankong-ui-api'

  export default {
    data() {
      return {
        timers: null,
        runTimers: null,
        processTimers: null,
        id: '',
        monitorNames: {},
        cpuCount: 0,
        processId: 0,
        processCpuUserd: 0,
        processSysCpuCount: 0,
        processRunTime: 0,
        gabTimers: null,
        gabrageList: {
          totals: 0,
          totalTimes: 0,
          maxTimes: 0
        },
        healthyDetailInfos: {},
        isHealthFlag: false
      };
    },
    components: {
      MemoryEharts,
      CpuEharts,
      LineEcharts,
      HealthItem
    },
    created(){
      this.monitorNames = JSON.parse(window.localStorage.getItem('setMonitorName'));
      this.id = this.$route.query.id;

     
      this.getProcessIDs();
      this.getRuningTimes();
      this.getProcessUserd();
      this.getSysUserdEvent();
      this.getCPUCount(); // 不需要轮训

      this.getGarbageList();
      this.getHealthInfoLists();
      
      // 1个小时更新一次 进程ID
      this.processTimers = setInterval(()=>{
        this.getProcessIDs();
      }, 3600000);

      this.timers = setInterval(()=>{
        this.getProcessUserd();
        this.getSysUserdEvent();
      }, 2000);

      this.runTimers = setInterval(()=>{
         this.getRuningTimes();
      }, 1000);

      this.gabTimers = setInterval(()=>{
        this.getGarbageList();
      }, 60000)

    },
    methods: {
      // cpu核心数
      getCPUCount(){
        monitormanagerAPI.getCpuCount(this.id).then(res=>{
          this.cpuCount = res.measurements[0].value;
        })
      },
      // 进程Id
      getProcessIDs(){
        monitormanagerAPI.getProcessID(this.id).then(res=>{
          this.processId = res.propertySources[0].properties.value.value;
        })
      },
      // 进程使用率
      getProcessUserd(){
        monitormanagerAPI.getProcessUsedCount(this.id).then(res=>{
          this.processCpuUserd = res.measurements[0].value.toFixed(2);
        })
      },
      // 系统使用率
      getSysUserdEvent(){
        monitormanagerAPI.getProcssSysCpuUserd(this.id).then(res=>{
          this.processSysCpuCount = res.measurements[0].value.toFixed(2);
        })
      },
      // 运行时间
      getRuningTimes(){
        monitormanagerAPI.getUserdTimes(this.id).then(res=>{
          let getTimes = this.formatSeconds(res.measurements[0].value)
          this.processRunTime = getTimes;
        })
      },
      // 时间转换为 -天-小时-分钟-秒
      formatSeconds(value) { 
        let theTime = parseInt(value);// 需要转换的时间秒 
        let theTime1 = 0;// 分 
        let theTime2 = 0;// 小时 
        let theTime3 = 0;// 天
        if(theTime > 60) { 
          theTime1 = parseInt(theTime/60); 
          theTime = parseInt(theTime%60); 
          if(theTime1 > 60) { 
            theTime2 = parseInt(theTime1/60); 
            theTime1 = parseInt(theTime1%60); 
            if(theTime2 > 24){
              //大于24小时
              theTime3 = parseInt(theTime2/24);
              theTime2 = parseInt(theTime2%24);
            }
          } 
        } 
        let result = '';
        if(theTime > 0){
          result = "" + parseInt(theTime) + "s ";
        }
        if(theTime1 > 0) { 
          result = "" + parseInt(theTime1) + "m " + result; 
        } 
        if(theTime2 > 0) { 
          result = "" + parseInt(theTime2) + "h " + result; 
        } 
        if(theTime3 >= 0) { 
          result = "" + parseInt(theTime3) + "d " + result; 
        }
        return result; 
      },
      // 垃圾回收
      getGarbageList(){
        monitormanagerAPI.getGarbageCollection(this.id).then(res=>{
            this.gabrageList.totals = res.measurements[0].value;
            this.gabrageList.totalTimes = res.measurements[1].value.toFixed(4);
            this.gabrageList.maxTimes = res.measurements[2].value.toFixed(4);
        })
      },
      // 健康--es
      getHealthInfoLists(){
        monitormanagerAPI.getHealthyInfoList(this.id).then(res=>{
          if(res.details) {
            this.isHealthFlag = true;
            this.healthyDetailInfos = res.details.components.details.elasticsearch.details.details;
          } else if(res.details == undefined){
            this.healthyDetailInfos = {};
            this.isHealthFlag = false;
          }
        })
      }
    },
    destroyed(){
      clearInterval(this.timers)
      clearInterval(this.runTimers)
      clearInterval(this.processTimers)
      clearInterval(this.gabTimers)
    }
  };
</script>
<style lang="less" scoped>
.server-wraps {
  padding: 25px;
}
.level {
  display: flex;
  justify-content: space-around;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      display: inline-block;
      text-align: center;
      margin: 10px 0;
      font-size: 15px;
    }
  }
}
.boxs {
  display: flex;
  justify-content: flex-end;
  .up-downbtn {
    margin-bottom: 20px;
  }
}
</style>