<template>
    <div>
        <div id="myLineChart" style="width: 500px;height: 400px;"></div>
    </div>
</template>
<script>
import * as mecharts from 'echarts'
import { monitormanagerAPI } from 'liankong-ui-api'
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            myChart: '',
            activeArr: [], // 活动线程 数据存储
            peakArr: [], // 守护线程 数据存储
            listTimer: null,
            lineTimers: null,
            lineActiveCount: 0,
            linePeakCount: 0,
            lineSaveCount: 0,
            option: {},
            keepDataDown:[],
            keepDataUp:[]
        }
    },
    created(){
        this.getLinePeakLists();
        this.getLineActiveLists();
        this.getLineSaveList();
        
       

        this.listTimer = setInterval(()=>{
            this.getLineActiveLists();
            this.getLineSaveList();
        }, 1000)
    },
    mounted(){
        this.lineTimers = setInterval(()=>{
            let base = new Date().getTime(); // 当前时间戳
            // 当前时间的前 半 个小时
            let frontOneHour = new Date(new Date().getTime() - 1 * 60 * 30 * 1000);
            // 当前时间的前 半 个小时 ==> 时间戳
            let frontTimes = Date.parse(frontOneHour);
            // 前 半 个小时
            let prevHour = 1 * 60 * 30 * 1000

            let lineSaveCounts = this.lineSaveCount - 1
            let arrCount = 0
            
            let data = [];
            let data2 = [];
            for (var i = 1; i < 10; i++) {
                var now = new Date((base -= prevHour));
               
                var dayStr = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' +  now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes(); // 年月日
                if(i % 2 == 0){
                    arrCount = lineSaveCounts
                } else {
                    arrCount = Number(this.lineSaveCount)
                }
                data.push([dayStr,arrCount]);
                data2.push([dayStr, this.lineActiveCount]);
                this.keepDataUp = data2
                this.keepDataDown = data
            }
            this.option = {
            legend: {
                top: 30,
                left: 'center',
                textStyle: {
                    fontSize: 14
                },
                data: [{
                    'name': '活动线程',
                    'icon': 'rect'
                },{
                    'name': '守护进程',
                    'icon': 'rect'
                },{
                    'name': '线程峰值',
                    'icon': 'none'
                }],
                formatter: (name)=>{
                    if(name === '活动线程') {
                        return name + "：" + this.lineActiveCount;
                    } else if(name === '守护进程') {
                        return name + "：" + this.lineSaveCount;
                    } else if(name === '线程峰值') {
                        return name + "：" + this.linePeakCount;
                    }
                }
            },
            tooltip: {
                triggerOn: 'none',
                position: function (pt) {
                    return [pt[0], 130];
                }
            },
            xAxis: {
                type: 'time',
                axisPointer: {
                    // value: '2016-10-7',
                    snap: true,
                    lineStyle: {
                        color: '#7581BD',
                        width: 2
                    },
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    showMaxLabel: true
                }
            },
            yAxis: {
                type: 'value',
                axisTick: { // 刻度
                    show: true,
                    inside: false,
                    boundaryGap: true,
                    alignWithLabel: true,
                },
                splitLine: {
                 show: false
                },
                axisLabel: { // 刻度标签
                    inside: true,
                    formatter: [0,50,100,150,200]
                },
                axisLine: {
                    show: true,
                },
                z: 10
            },
            grid: {
                top: 110,
                left: 15,
                right: 15,
                height: 160
            },
            series: [{
                        name: '活动线程',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(213,72,120,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(213,72,120,0.3)'
                            }
                            ])
                        },
                        data: data2
                    },
                    {
                    name: '守护进程',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#0770FF'
                    },
                    stack: 'a',
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(58,77,233,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(58,77,233,0.3)'
                        }
                        ])
                    },
                        data: data
                    },
                    {
                        name: '线程峰值',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
            };

            this.myChart.setOption(this.option);
        }, 1800000)
    },
    methods: {
        // 活动线程
        getLineActiveLists(){
            monitormanagerAPI.getLineActiveList(this.id).then(res=>{
                this.lineActiveCount = res.measurements[0].value;

                let that = this
                that.option.legend = {
                    top: 30,
                    left: 'center',
                    textStyle: {
                        fontSize: 14
                    },
                    data: [{
                        'name': '活动线程',
                        'icon': 'rect'
                    },{
                        'name': '守护进程',
                        'icon': 'rect'
                    },{
                        'name': '线程峰值',
                        'icon': 'none'
                    }],
                    formatter: (name)=>{
                        if(name === '活动线程') {
                            return name + "：" + this.lineActiveCount;
                        } else if(name === '守护进程') {
                            return name + "：" + this.lineSaveCount;
                        } else if(name === '线程峰值') {
                            return name + "：" + this.linePeakCount;
                        }
                    }
                }
                that.keepDataUp.forEach((item,i)=>{
                    item[1] = that.lineActiveCount;
                })
                that.option.series = [{
                        name: '活动线程',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(213,72,120,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(213,72,120,0.3)'
                            }
                            ])
                        },
                        data: that.keepDataUp
                    },
                    {
                    name: '守护进程',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#0770FF'
                    },
                    stack: 'a',
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(58,77,233,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(58,77,233,0.3)'
                        }
                        ])
                    },
                        data: that.keepDataDown
                    },
                    {
                        name: '线程峰值',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        }
                    }
                ]
                that.myChart.setOption(that.option);
            })
        },
        // 线程峰值
        getLinePeakLists(){
            monitormanagerAPI.getLinePeakList(this.id).then(res=>{
                this.linePeakCount = res.measurements[0].value;

                this.onloads();
            })
        },
        // 守护进程
        getLineSaveList(){
            monitormanagerAPI.getLineSaveList(this.id).then(res=>{
                this.lineSaveCount = res.measurements[0].value;

                let that = this
                that.option.legend = {
                    top: 30,
                    left: 'center',
                    textStyle: {
                        fontSize: 14
                    },
                    data: [{
                        'name': '活动线程',
                        'icon': 'rect'
                    },{
                        'name': '守护进程',
                        'icon': 'rect'
                    },{
                        'name': '线程峰值',
                        'icon': 'none'
                    }],
                    formatter: (name)=>{
                        if(name === '活动线程') {
                            return name + "：" + this.lineActiveCount;
                        } else if(name === '守护进程') {
                            return name + "：" + this.lineSaveCount;
                        } else if(name === '线程峰值') {
                            return name + "：" + this.linePeakCount;
                        }
                    }
                }
                let lineSaveCounts = this.lineSaveCount - 1
                let arrCount = 0
               
                this.keepDataDown.forEach((item,i)=>{
                    if(i % 2 == 0){
                        item[1] = lineSaveCounts
                    } else {
                        item[1] = Number(this.lineSaveCount)
                    }
                })

                that.option.series = [{
                        name: '活动线程',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(213,72,120,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(213,72,120,0.3)'
                            }
                            ])
                        },
                        data: that.keepDataUp
                    },
                    {
                    name: '守护进程',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#0770FF'
                    },
                    stack: 'a',
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(58,77,233,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(58,77,233,0.3)'
                        }
                        ])
                    },
                        data: that.keepDataDown
                    },
                    {
                        name: '线程峰值',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        }
                    }
                ]
                that.myChart.setOption(that.option);
            })
        },
        onloads(){
            if(this.myChart!='' && this.myChart!= null){
                this.myChart.dispose();
            }
            this.myChart = mecharts.init(document.getElementById('myLineChart'));
            // let options

            let base = new Date().getTime(); // 当前时间戳
            // 当前时间的前 半 个小时
            let frontOneHour = new Date(new Date().getTime() - 1 * 60 * 30 * 1000);
            // 当前时间的前 半 个小时 ==> 时间戳
            let frontTimes = Date.parse(frontOneHour);
            // 前 半 个小时
            let prevHour = 1 * 60 * 30 * 1000

            let lineSaveCounts = this.lineSaveCount - 1
            let arrCount = 0
            
            let data = [];
            let data2 = [];
            for (var i = 1; i < 10; i++) {
                var now = new Date((base -= prevHour));
               
                var dayStr = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' +  now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes(); // 年月日
                if(i % 2 == 0){
                    arrCount = lineSaveCounts
                } else {
                    arrCount = Number(this.lineSaveCount)
                }
                data.push([dayStr,arrCount]);
                data2.push([dayStr, this.lineActiveCount]);
                this.keepDataUp = data2
                this.keepDataDown = data
            }
            this.option = {
            legend: {
                top: 30,
                left: 'center',
                textStyle: {
                    fontSize: 14
                },
                data: [{
                    'name': '活动线程',
                    'icon': 'rect'
                },{
                    'name': '守护进程',
                    'icon': 'rect'
                },{
                    'name': '线程峰值',
                    'icon': 'none'
                }],
                formatter: (name)=>{
                    if(name === '活动线程') {
                        return name + "：" + this.lineActiveCount;
                    } else if(name === '守护进程') {
                        return name + "：" + this.lineSaveCount;
                    } else if(name === '线程峰值') {
                        return name + "：" + this.linePeakCount;
                    }
                }
            },
            tooltip: {
                triggerOn: 'none',
                position: function (pt) {
                    return [pt[0], 130];
                }
            },
            xAxis: {
                type: 'time',
                axisPointer: {
                    // value: '2016-10-7',
                    snap: true,
                    lineStyle: {
                        color: '#7581BD',
                        width: 2
                    },
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    showMaxLabel: true
                }
            },
            yAxis: {
                type: 'value',
                axisTick: { // 刻度
                    show: true,
                    inside: false,
                    boundaryGap: true,
                    alignWithLabel: true,
                },
                splitLine: {
                 show: false
                },
                axisLabel: { // 刻度标签
                    inside: true,
                    formatter: [0,50,100,150,200]
                },
                axisLine: {
                    show: true,
                },
                z: 10
            },
            grid: {
                top: 110,
                left: 15,
                right: 15,
                height: 160
            },
            series: [{
                        name: '活动线程',
                        type: 'line',
                        smooth: true,
                        symbol:'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                        areaStyle: {
                            color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(213,72,120,0.8)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(213,72,120,0.3)'
                            }
                            ])
                        },
                        data: data2
                    },
                    {
                    name: '守护进程',
                    type: 'line',
                    smooth: true,
                    stack: 'a',
                    symbol: 'none',
                    symbolSize: 5,
                    sampling: 'average',
                    itemStyle: {
                        color: '#0770FF'
                    },
                    stack: 'a',
                    areaStyle: {
                        color: new mecharts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(58,77,233,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(58,77,233,0.3)'
                        }
                        ])
                    },
                        data: data
                    },
                    {
                        name: '线程峰值',
                        type: 'line',
                        smooth: true,
                        stack: 'a',
                        symbol: 'none',
                        symbolSize: 5,
                        sampling: 'average',
                        itemStyle: {
                            color: '#F2597F'
                        },
                    }
                ]
            };

            this.option && this.myChart.setOption(this.option);
        },


    },
    destroyed(){
        clearInterval(this.listTimer);
        clearInterval(this.lineTimers);
    }
}
</script>
<style lang="less" scoped>

</style>