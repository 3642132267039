<template>
    <div class="header-infos">
        <div>
            <span>cluster_name</span>
            <span v-if="healthyDetailInfos.details.cluster_name">{{healthyDetailInfos.details.cluster_name}}</span>
        </div>
        <div>
            <span>status</span>
            <span v-if="healthyDetailInfos.status">{{healthyDetailInfos.status}}</span>
        </div>
        <div>
            <span>timed_out</span>
            <span v-if="!healthyDetailInfos.details.timed_out">{{healthyDetailInfos.details.timed_out}}</span>
        </div>
        <div>
            <span>number_of_nodes</span>
            <span v-if="healthyDetailInfos.details.number_of_nodes">{{healthyDetailInfos.details.number_of_nodes}}</span>
        </div>
        <div>
            <span>number_of_data_nodes</span>
            <span v-if="healthyDetailInfos.details.number_of_data_nodes">{{healthyDetailInfos.details.number_of_data_nodes}}</span>
        </div>
        <div>
            <span>active_primary_shards</span>
            <span v-if="healthyDetailInfos.details.active_primary_shards">{{healthyDetailInfos.details.active_primary_shards}}</span>
        </div>
        <div>
            <span>active_shards</span>
            <span v-if="healthyDetailInfos.details.active_shards">{{healthyDetailInfos.details.active_shards}}</span>
        </div>
            <div>
            <span>relocating_shards</span>
            <span v-if="!healthyDetailInfos.details.relocating_shards">{{healthyDetailInfos.details.relocating_shards}}</span>
        </div>
            <div>
            <span>initializing_shards</span>
            <span v-if="!healthyDetailInfos.details.initializing_shards">{{healthyDetailInfos.details.initializing_shards}}</span>
        </div>
            <div>
            <span>unassigned_shards</span>
            <span v-if="healthyDetailInfos.details.unassigned_shards">{{healthyDetailInfos.details.unassigned_shards}}</span>
        </div>
            <div>
            <span>delayed_unassigned_shards</span>
            <span v-if="!healthyDetailInfos.details.delayed_unassigned_shards">{{healthyDetailInfos.details.delayed_unassigned_shards}}</span>
        </div>
            <div>
            <span>number_of_pending_tasks</span>
            <span v-if="!healthyDetailInfos.details.number_of_pending_tasks">{{healthyDetailInfos.details.number_of_pending_tasks}}</span>
        </div>
            <div>
            <span>number_of_in_flight_fetch</span>
            <span v-if="!healthyDetailInfos.details.number_of_in_flight_fetch">{{healthyDetailInfos.details.number_of_in_flight_fetch}}</span>
        </div>
            <div>
            <span>task_max_waiting_in_queue_millis</span>
            <span v-if="!healthyDetailInfos.details.task_max_waiting_in_queue_millis">{{healthyDetailInfos.details.task_max_waiting_in_queue_millis}}</span>
        </div>
            <div>
            <span>active_shards_percent_as_number</span>
            <span v-if="healthyDetailInfos.details.active_shards_percent_as_number">{{healthyDetailInfos.details.active_shards_percent_as_number}}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        healthyDetailInfos: {
            type: Object
        }
    },
    data(){
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.header-infos {
  div {
    height: 40px;
    border-bottom: 1px solid  #EBEEF5;
    line-height: 40px;
    span:nth-child(1){
      width: 250px;
      margin-right: 150px;
      display: inline-block;
    }
  }
}
</style>